/** @jsx jsx */
import { jsx } from "theme-ui"
import { Products, TheLayout } from "../components"
import Img from "gatsby-image"

const TheTiles = ({ data }) => {
  return (
    <TheLayout title="The Tiles" unconfined sx={{}}>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: ["auto", "auto", "1fr 1fr", "1fr 2fr"],
        }}
      >
        <div
          sx={{
            position: "relative",
          }}
        >
          <Img
            fluid={data.coverImage.childImageSharp.also}
            title="words"
            style={{ position: "unset", overflow: "visible" }}
            // imgStyle={{ opacity: '.6' }}
            // durationFadeIn={5000}
            backgroundColor="#F9D6CE"
            // placeholderStyle={{ backgroundColor: `black` }}
          />
        </div>
        <Products />
      </div>
    </TheLayout>
  )
}

export default TheTiles

export const ProductImageQuery = graphql`
  query {
    coverImage: file(relativePath: { regex: "/bigcontent_left_regular/" }) {
      childImageSharp {
        fluid(
          # maxWidth: 800
          quality: 80
          duotone: { highlight: "#ffffff", shadow: "#663399" }
        ) {
          ...GatsbyImageSharpFluid
        }
        also: fluid(
          # maxWidth: 800
          quality: 80 # duotone: { highlight: "#ffffff", shadow: "#3bbad7" }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
